<template>
  <div>
    <!-- <navtop></navtop>
    <menuNav /> -->
    <div class="box">
      <div style="padding: 20px 0">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/topiccourse' }"
            >专题课程</el-breadcrumb-item
          >
          <el-breadcrumb-item>课程详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="content">
        <div class="clearfix">
          <div class="fl radio">
            <div>
              <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
              ></video-player>
            </div>
          </div>
          <div class="fr info">
            <div class="title">
              <img
                src="../../assets/image/title.png"
                alt=""
                style="margin-right: 5px; color: #2f2f2f"
              />
              课程简介
            </div>
            <div
              style="
                font-size: 14px;
                text-indent: 2em;
                color: #737881;
                line-height: 24px;
              "
            >
              {{ list.course_brief ? list.course_brief : "暂无数据" }}
            </div>
          </div>
        </div>
        <div v-if="list.course_name">
          <div class="coursetitle">{{ list.course_name }}</div>
          <div style="font-size: 14px; color: #393b3d">
            <span style="color: #a7a8a8; margin-right: 10px">观看量:</span
            ><span>{{ list.course_views }}</span
            ><span style="margin: 0 30px">|</span
            ><span style="margin-right: 10px">分享给朋友:</span
            ><img
              src="../../assets/image/qq2.png"
              alt=""
              @click="toQQ()"
              style="
                vertical-align: middle;
                margin-right: 10px;
                cursor: pointer;
              "
            />
            <!-- <img
              src="../../assets/image/wechat.png"
              alt=""
              style="vertical-align: middle; margin-right: 10px"
            /> -->
            <img
              @click="toWeibo()"
              src="../../assets/image/webo2.png"
              alt=""
              style="vertical-align: middle; cursor: pointer"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import URL from "../../api/index";
import navtop from "@/components/navtop.vue";
import menuNav from "@/components/menuNav.vue";
import Footer from "../../components/footer.vue";
export default {
  data() {
    return {
      list: {},
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: require("../../assets/image/sou.mp4"), //本地地址
            // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",//url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: true, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      
    };
  },
  components: { navtop, menuNav, Footer },
  mounted() {
    this.getvideo();
    this.getcount();
  },
  methods: {
    getvideo() {
      var data = {
        course_id: this.$route.query.id,
        // token: this.token,
      };
      this.$post(URL.getcourseinfo, data).then((res) => {
        if (!localStorage.getItem("loginInfo")) {
          if (res.result.course_free == "1") {
            this.list = res.result;
            this.playerOptions.sources[0].src = res.result.course_url;
          } else {
            this.list = {};
          }
        } else {
          this.list = res.result;
          this.playerOptions.sources[0].src = res.result.course_url;
        }
      });
    },
    // 观看次数
    getcount() {
      var data = {
        course_id: this.$route.query.id,
        // token: JSON.parse(localStorage.getItem("loginInfo")).token,
      };
      this.$post(URL.getviewcount, data).then((res) => {
        // console.log(res);
      });
    },
    toWeibo() {
      let title = this.list.course_name;
      let url = window.location.href;
      let content = "搜老师优质资源";

      var sharesinastring =
        "http://service.weibo.com/share/share.php?title=" +
        encodeURIComponent(content + "「" + title + "」" + " 点这里" + url);
      window.open(sharesinastring, "_blank");
    },
    toQQ() {
      let title = this.list.course_name;
      let url = window.location.href;
      var _shareUrl =
        "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?";
      _shareUrl += "url=" + encodeURIComponent(url);
      _shareUrl += "&title=" + encodeURIComponent(title);
      window.open(_shareUrl, "_blank");
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 90px;
  .content {
    box-sizing: border-box;
    opacity: 1;
    border-radius: 16px;
    height: 650px;
    padding: 30px;
    background: #ffffff;
    .radio {
      box-sizing: border-box;
      width: 865px;
      // height: 582px;
      background: #1b1b1b;
      opacity: 1;
      border-radius: 16px;
    }
    .info {
      padding: 20px;
      box-sizing: border-box;
      width: 255px;
      height: 490px;
      background: #f1f3f7;
      opacity: 1;
      border-radius: 16px;
      .title {
        padding-bottom: 20px;
      }
    }
    .coursetitle {
      font-size: 24px;
      color: #2f2f2f;
      padding: 20px 0;
    }
  }
}
</style>
